import React from "react";

import imgBoards from "../images/boards.svg";

const BoardsPage = () => {
  return (
    <center>
      <br />
      <br />
      <br />
      <br />
      <br />
      <img src={imgBoards} width="100" height="100" />
      <br />
      <br />
      <h4>
        Introducing Copeo Boards <br />
        for Easy Task and Project Management
      </h4>

      <h4>
        <a href="https://boards.copeo.app" target="_blank">
          Launch Boards
        </a>
      </h4>
      <br />
      {/* <h6>
        <a href="/#/boardreports">Loking for reports ?</a>
      </h6> */}
      <br />
      <br />
      <br />
      <br />
      <br />
    </center>
  );
};

export default BoardsPage;
