import "../css/styles.css";
import "../css/App.css";
import atlasConfig from "../atlasConfig.json";
import { AppProvider, useApp } from "./RealmApp";
import { useState } from "react";
import { ThemeProvider } from "./Theme";
const { appId } = atlasConfig;
// import { Toolbar, Typography, Button, AppBar } from "@mui/material";
import { WelcomePage } from "./WelcomePage";
import NotFoundPage from "./NotFoundPage";
import BoardsPage from "./BoardsPage";
import { HomePage } from "./HomePage";
import { ProjectOptionsPage } from "./ProjectOptionsPage";
import { ProjectPage } from "./ProjectPage";
import { Routes, Route } from "react-router-dom";
import AppLayout from "./AppLayout";
import { CreateOrganizationPage } from "./CreateOrganizationPage";
import { AccountSettingsPage } from "./AccountSettingsPage";
import { DashboardPage } from "./DashboardPage";

// import EditableTable from "./components/EditableTable";

export default function ProvidedApp() {
  return (
    <ThemeProvider>
      <AppProvider appId={appId}>
        <App />
      </AppProvider>
    </ThemeProvider>
  );
}

// function App() {
//   const { currentUser, logOut } = useApp();

//   return (
//     <div className="App">
//       <AppBar position="sticky">
//         <Toolbar disableGutters>
//           <AppName />

//           {currentUser ? (
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={async () => {
//                 await logOut();
//               }}
//             >
//               <Typography variant="button">Log Out</Typography>
//             </Button>
//           ) : null}
//         </Toolbar>
//       </AppBar>
//       <Routes>
//         {/* <Route path="/" element={<HomePage />} />
//         <Route path="/login" element={<WelcomePage />} /> */}
//         <Route element={<ProjectsLayout />}>
//           <Route index element={<LoadProjectPage />} />
//           <Route path=":id" element={<ProjectPage />} />
//           <Route path="new" element={<ProjectPage />} />
//         </Route>
//         <Route path="*" element={<WelcomePage />} />
//       </Routes>

//       {/* {currentUser ? (
//         <>
//           <ResponsiveAppBar />
//           <LoadProjectPage />
//         </>
//       ) : (
//         <>
//           <AppBar position="sticky">
//             <Toolbar disableGutters>
//               <AppName />

//               {currentUser ? (
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={async () => {
//                     await logOut();
//                   }}
//                 >
//                   <Typography variant="button">Log Out</Typography>
//                 </Button>
//               ) : null}
//             </Toolbar>
//           </AppBar>
//           <WelcomePage />
//         </>
//       )} */}
//     </div>
//   );
// }

function App() {
  const { currentUser, logOut } = useApp();
  return (
    <div>
      {currentUser ? (
        <>
          <Routes>
            {/* <Route
              path="/"
              element={<AppLayout currentUser={currentUser} logOut={logOut} />}
            />
            <Route
              path="/login"
              element={<AppLayout currentUser={currentUser} logOut={logOut} />}
            /> */}
            <Route
              path="/"
              element={<AppLayout currentUser={currentUser} logOut={logOut} />}
            >
              {/* <Route
                path="/organizations"
                element={<CreateOrganizationPage />}
              />
              <Route
                path="/organizations/new"
                element={<CreateOrganizationPage />}
              />
              <Route
                path="/organizations/:id"
                element={<CreateOrganizationPage />}
              /> */}
              <Route index element={<DashboardPage />} />
              <Route path="/projects" element={<ProjectOptionsPage />} />
              <Route path="/projects/:id" element={<ProjectPage />} />

              <Route
                path="/account"
                element={
                  <AccountSettingsPage
                    customData={currentUser.customData}
                    currentUser={currentUser}
                  />
                }
              />
              <Route path="/boards" element={<BoardsPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/resetPassword" element={<NotFoundPage />} />
            <Route path="/confirmEmail" element={<NotFoundPage />} />
            <Route path="*" element={<WelcomePage />} />
          </Routes>
        </>
      )}
    </div>
  );
}
