import React from "react";
import { Table, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getStatusColor } from "../shared";
import { Tooltip } from "@material-ui/core";

const TaskCompletionPercentTable = ({ currentUser, markers, projectName }) => {
  const completionPercentRows = getCompletionPercentagesByTask().map(
    (item, index) => (
      <tr key={index}>
        <td>
          <center>
            <b>{item.task}</b>
          </center>
        </td>
        <td>
          <center style={{ maxWidth: 300, "font-size": "13px" }}>
            {item.taskdescription}
          </center>
        </td>

        <td>
          <center>{item.totalMarkers}</center>
        </td>
        <td style={{ color: getStatusColor("notstarted") }}>
          <center>{item.notStartedMarkers}</center>
        </td>
        <td style={{ color: getStatusColor("inprogress") }}>
          <center>{item.inProgressMarkers}</center>
        </td>
        <td style={{ color: getStatusColor("completed") }}>
          <center>{item.completedMarkers}</center>
        </td>
        <td>
          {/* {item.percentCompleted.toFixed(2)}% */}
          <ProgressBar
            now={item.percentCompleted}
            variant="success"
            label={`${item.percentCompleted.toFixed(2)}%`}
          />
        </td>
        <td>
          {/* {item.percentCompleted.toFixed(2)}% */}
          <ProgressBar
            now={item.percentVerified}
            label={`${item.percentVerified.toFixed(2)}%`}
          />
        </td>
      </tr>
    )
  );

  function getCompletionPercentagesByTask() {
    const markersByTask = markers.reduce((acc, cur) => {
      if (cur.task) {
        if (!acc[cur.task]) {
          acc[cur.task] = [];
        }
        acc[cur.task].push(cur);
      }
      return acc;
    }, {});

    return Object.entries(markersByTask).map(([task, taskMarkers]) => {
      const totalMarkers = taskMarkers.length;
      const notStartedMarkers = taskMarkers.filter(
        (m) => m.status === "notstarted"
      ).length;

      const inProgressMarkers = taskMarkers.filter(
        (m) => m.status === "inprogress"
      ).length;
      const completedMarkers = taskMarkers.filter(
        (m) => m.status === "completed" || m.status === "verified"
      ).length;
      const percentCompleted =
        totalMarkers > 0 ? (completedMarkers / totalMarkers) * 100 : 0;

      const verifiedMarkers = taskMarkers.filter(
        (m) => m.status === "verified"
      ).length;
      const percentVerified =
        totalMarkers > 0 ? (verifiedMarkers / totalMarkers) * 100 : 0;

      //get the taskdescription property from the markers
      function getProperty(arr, key) {
        return arr.map((obj) => obj[key]);
      }

      const noDupArray = [
        ...new Set(getProperty(taskMarkers, "taskdescription")),
      ];
      const taskdescription = noDupArray.length > 0 ? noDupArray[0] : "";
      return {
        task,
        taskdescription,
        totalMarkers,
        completedMarkers,
        percentCompleted,
        percentVerified,
        inProgressMarkers,
        notStartedMarkers,
      };
    });
  }

  function getCompletedCount(type1, type2) {
    if (type2)
      return markers.filter((m) => m.status === type1 || m.status === type2)
        .length;
    else return markers.filter((m) => m.status === type1).length;
  }

  return (
    <div className="container">
      {currentUser && (
        <>
          <Tooltip
            title={"You can change this logo in the Settings & Account tab"}
          >
            <img src={currentUser.customData.userBusinessLogoUrl} width={200} />
          </Tooltip>
        </>
      )}
      <br />
      <br />
      <br />
      <h2 style={{ "text-align": "left" }}>Overall Task Completion Report</h2>
      <h4 style={{ "text-align": "right" }}>
        {" "}
        <i>{projectName}</i>
      </h4>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <center>Task</center>
            </th>
            <th>
              <center>Description</center>
            </th>
            <th>
              <center>Total Markers</center>
            </th>
            <th>
              <center>Not Started</center>
            </th>
            <th>
              <center>In Progress</center>
            </th>
            <th>
              <center>Completed Markers</center>
            </th>
            <th>
              <center>Percent Completed</center>
            </th>
            <th>
              <center>Percent Verified</center>
            </th>
          </tr>
        </thead>
        <tbody>
          {completionPercentRows}
          <tr>
            <td>
              <center>
                <b>Totals</b>
              </center>
            </td>
            <td>
              <center>
                <b>N/A</b>
              </center>
            </td>
            <td>
              <center>
                <b>{markers.length}</b>
              </center>
            </td>
            <td>
              <center>
                <b>{getCompletedCount("notstarted", null)}</b>
              </center>
            </td>
            <td>
              <center>
                <b>{getCompletedCount("inprogress", null)}</b>
              </center>
            </td>
            <td>
              <center>
                <b>{getCompletedCount("completed", "verified")}</b>
              </center>
            </td>
            <td style={{ color: getStatusColor("completed") }}>
              <center>
                <b>
                  {getCompletedCount("completed", "verified")} of{" "}
                  {markers.length} ={" "}
                  {isNaN(
                    (getCompletedCount("completed", "verified") /
                      markers.length) *
                      100
                  )
                    ? 0
                    : (
                        (getCompletedCount("completed", "verified") /
                          markers.length) *
                        100
                      ).toFixed(2)}
                  %
                </b>
              </center>
            </td>
            <td style={{ color: getStatusColor("verified") }}>
              <center>
                <b>
                  {getCompletedCount("verified")} of {markers.length} ={" "}
                  {isNaN((getCompletedCount("verified") / markers.length) * 100)
                    ? 0
                    : (
                        (getCompletedCount("verified") / markers.length) *
                        100
                      ).toFixed(2)}
                  %
                </b>
              </center>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TaskCompletionPercentTable;
