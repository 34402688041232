import React from "react";
import { useApp } from "./RealmApp";
import {
  Container,
  Button,
  Input,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import settingslogo from "../images/settings2.png";
import { useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { Table, Tab, Tabs, Stack, Accordion } from "react-bootstrap";
import {
  shared_TaskStatusOptions,
  getStatusColor,
  markerSizes,
  generateId,
  defaultProfileImage,
} from "../shared";

// {
//   owner_id: user.id,
// userDisplayName: "",
// userPhone:"",
// userBusinessName : "",
// userBusinessAddress: "",
// userTimeZone:"",
// userThemePreference:"light",
// userOrganizationId: generateOrganizationId(),
// userLayoutPreference: "createFixedLayout",
// userNavBarPreferenece: true,
// userProfileImage: ""
// }
export function AccountSettingsPage({ customData, currentUser }) {
  const [userCustomData, setUserCustomData] = useState(
    customData
      ? customData
      : {
          userDisplayName: "",
          userPhone: "",
          userBusinessName: "",
          userBusinessAddress: "",
          userBusinessLogoUrl: "",
          userTimeZone: "",
          userThemePreference: "light",
          userLayoutPreference: "createFixedLayout",
          userNavBarPreferenece: true,
          userProfileImage: "",
        }
  );
  const [displayName, setDisplayName] = useState(
    userCustomData.userDisplayName ? userCustomData.userDisplayName : ""
  );
  const [businessName, setBusinessName] = useState(
    userCustomData.userBusinessName ? userCustomData.userBusinessName : ""
  );
  const [businessLogo, setBusinessLogo] = useState(
    userCustomData.userBusinessLogoUrl ? userCustomData.userBusinessLogoUrl : ""
  );
  const [businessAddress, setBusinessAddress] = useState(
    userCustomData.userBusinessAddress ? userCustomData.userBusinessAddress : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    userCustomData.userPhone ? userCustomData.userPhone : ""
  );
  const [defaultTheme, setDefaultTheme] = useState(
    userCustomData.userThemePreference
      ? userCustomData.userThemePreference
      : "light"
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    currentUser.customData.userTimeZone
      ? currentUser.customData.userTimeZone
      : ""
  );

  const [profileImageBase64String, setProfileImageBase64String] = useState(
    currentUser
      ? currentUser.customData.userProfileImage == ""
        ? defaultProfileImage
        : currentUser.customData.userProfileImage
      : defaultProfileImage
  );

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file != null) {
      if (file.size / 1024 / 1024 < 1) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          setProfileImageBase64String(reader.result);
        };

        reader.onerror = () => {
          //console.log("Error occurred while reading the file");
        };
      } else {
        setSnackBarState({
          ...snackBar,
          open: true,
          message: "Please upload a smaller file (<1MB)",
        });
      }
    }
  };

  return (
    <Container className="main-container">
      <Tabs defaultActiveKey="settings" className="mb-3">
        <Tab eventKey="settings" title="Account Configuration">
          <div className="container">
            <center>
              <img src={settingslogo} />
            </center>
            <h1>Account Setup</h1>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>User Account Details</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack direction="vertical">
                    Display Name:
                    <br />
                    <Input
                      value={displayName}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setDisplayName(value);
                        //console.log(value);
                      }}
                    />
                    <br />
                    Business Name:
                    <br />
                    <Input
                      value={businessName}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setBusinessName(value);
                      }}
                    />
                    <br />
                    Business Address:
                    <br />
                    <Input
                      value={businessAddress}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setBusinessAddress(value);
                      }}
                    />
                    <br />
                    Phone Number (Business/Personal):
                    <br />
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      value={phoneNumber}
                      onChange={(value) => {
                        setPhoneNumber(value);
                      }}
                    />
                    <br />
                    Your TimeZone (Business/Personal):
                    <br />
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                      timezones={{
                        ...allTimezones,
                        "America/Lima": "Pittsburgh",
                        "Europe/Berlin": "Frankfurt",
                      }}
                    />
                    <br />
                    Business Logo (Must be a public URL):
                    <br />
                    <Input
                      value={businessLogo}
                      type={"url"}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setBusinessLogo(value);
                      }}
                    />
                    <br />
                    <Button
                      variant="contained"
                      color="success"
                      size="sm"
                      // disabled={disableProjectName}
                      onClick={async () => {
                        const updatedData = {
                          userBusinessName: businessName,
                          userDisplayName: displayName,
                          userPhone: phoneNumber,
                          userTimeZone: selectedTimezone.value,
                          userBusinessAddress: businessAddress,
                          userBusinessLogoUrl: businessLogo,
                        };

                        const result =
                          await currentUser.functions.UpdateUserCustomData(
                            updatedData
                          );
                        if (result) {
                          alert("Saved");
                        }
                        await currentUser.refreshCustomData();
                        //console.log(result);
                      }}
                    >
                      Save
                    </Button>
                    <br />
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h4>Profile Picture</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack direction="vertical">
                    <Form.Group>
                      <Form.Label>Default Theme:</Form.Label>
                      <Form.Control
                        as="select"
                        value={setDefaultTheme}
                        onChange={(e) => {
                          //console.log("e.target.value", e.target.value);
                          setDefaultTheme(e.target.value);
                        }}
                      >
                        <option value="dark">Dark</option>
                        <option value="light">Light</option>
                      </Form.Control>
                      <br />
                      <Form.Label>Profile Picture:</Form.Label>
                      <br />
                      <img
                        src={profileImageBase64String}
                        width="100"
                        height="100"
                      />

                      <Form.Control
                        as="input"
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        onChange={handleImageUpload}
                      ></Form.Control>
                    </Form.Group>
                    <br />
                    <Button
                      variant="contained"
                      color="success"
                      size="sm"
                      // disabled={disableProjectName}
                      onClick={async () => {
                        const updatedData = {
                          userProfileImageData: profileImageBase64String,
                          owner_id: currentUser.id,
                          //userThemePreference: defaultTheme,
                        };
                        const result =
                          await currentUser.functions.UpdateUserProfilePicture(
                            updatedData
                          );
                        if (result) {
                          alert("Updated Profile Picture Successfully");
                        }
                        console.log(result);
                      }}
                    >
                      Save Profile Picture
                    </Button>
                  </Stack>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}
