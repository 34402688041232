import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { AppName } from "./AppName";
import { Stack } from "react-bootstrap";
import logo from "../images/logo.png";
import premiumuser from "../images/premiumuser.png";
import freeuser from "../images/free.png";
import { defaultProfileImage } from "../shared";
import { Tooltip } from "@mui/material";

const NavHeaderEx = ({ collapsed, currentUser, userProfileImage }) => (
  <>
    {/* <AppName currentUser={(currentUser, collapsed)} /> */}
    <Stack
      style={{ marginLeft: "10px", marginTop: "10px" }}
      direction="vertical"
    >
      <a href="/">
        <img
          src={logo}
          alt="Copeo Construction Progress Tracker"
          width="50"
          height="50"
        />
      </a>

      <Typography component="h6" variant="h6">
        {!collapsed && <>Copeo Construction Management</>}
      </Typography>

      <Divider style={{ margin: "12px 0" }} />
    </Stack>

    <div style={{ padding: collapsed ? 8 : 16, transition: "0.3s" }}>
      <Avatar
        style={{
          width: collapsed ? 48 : 60,
          height: collapsed ? 48 : 60,
          transition: "0.3s",
        }}
        // src={userProfileImage}
      />
      <div style={{ paddingBottom: 16 }} />
      <Stack direction="horizontal">
        {currentUser.customData.userPlan === "free" && (
          <Tooltip title={"Single Project Free User"}>
            <img
              src={freeuser}
              alt="Copeo Construction Progress Tracker"
              width="40"
              height="40"
            />
          </Tooltip>
        )}
        {currentUser.customData.userPlan === "premium" && (
          <Tooltip title={"Premium User"}>
            <img
              src={premiumuser}
              alt="Copeo Construction Progress Tracker"
              width="40"
              height="40"
            />
          </Tooltip>
        )}

        <Typography variant={"h6"} noWrap>
          {!collapsed && (
            <> {currentUser ? currentUser.customData.userDisplayName : ""}</>
          )}
        </Typography>
      </Stack>
      <Typography color={"textSecondary"} noWrap gutterBottom>
        {!collapsed && <>{currentUser ? currentUser.profile.email : ""}</>}
      </Typography>
    </div>
    <Divider />
  </>
);

NavHeaderEx.propTypes = {
  collapsed: PropTypes.bool,
};
NavHeaderEx.defaultProps = {
  collapsed: false,
};

export default NavHeaderEx;
