import { Container, Link } from "@mui/material";
export const MoreInfo = MoreInfoDocsLink

function MoreInfoItem({ children }) {
  return (
    <Container
      style={{ textAlign: "center", padding: "14px 0", marginTop: "auto" }}
    >
      {children}
    </Container>
  );
}

export function MoreInfoDocsLink() {
  const docsLink = new URL("https://www.paypal.com/paypalme/medhakarri");
  return (
    <MoreInfoItem>
      <span>{
        "Built for my brother, Victor"
      }</span> |{" "}
      <Link target="_blank" href={docsLink}>
        Donate
      </Link>
    </MoreInfoItem>
  );
}

