import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import FolderIcon from "@mui/icons-material/Folder";
import PeopleIcon from "@mui/icons-material/People";
import StarIcon from "@mui/icons-material/Star";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import PublishIcon from "@mui/icons-material/Publish";
import BackupIcon from "@mui/icons-material/Backup";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { Link } from "@mui/material";

function returnIcon(icon) {
  switch (icon) {
    case "feed":
      return <ModeCommentIcon />;
    case "projects":
      return <AccountTreeIcon />;
    case "people":
      return <PeopleIcon />;
    case "boards":
      return <AssignmentIcon />;
      return;
    case "star":
      return <StarIcon />;
    case "schedule":
      return <CalendarMonthIcon />;
    case "offline_pin":
      return <OfflinePinIcon />;
    case "publish":
      return <PublishIcon />;
    case "backup":
      return <BackupIcon />;
    case "delete":
      return <DeleteOutlineIcon />;
    case "newproject":
      return <AddIcon />;
    case "dashboard":
      return <SpaceDashboardIcon />;

    default:
      return <Icon />;
  }
}

const list = [
  // {
  //   primaryText: "Recent Activity",
  //   icon: "feed",
  //   href: "/",
  // },
  {
    primaryText: "Dashboard",
    icon: "dashboard",
    href: "/",
  },
  {
    primaryText: "Floorplan Projects",
    icon: "projects",
    href: "/#/projects",
  },
  {
    primaryText: "Boards",
    icon: "boards",
    href: "/#/boards",
  },
  // {
  //   primaryText: "New Project",
  //   icon: "newproject",
  //   href: "/projects/new",
  // },
  // {
  //   primaryText: "Shared with me",
  //   icon: "people",
  //   href: "/projects/new",
  // },

  // {
  //   primaryText: "Starred",
  //   icon: "star",
  //   href: "/projects",
  // },
  // {
  //   primaryText: "Recent",
  //   icon: "schedule",
  //   href: "/projects",
  // },
  // {
  //   primaryText: "Offline",
  //   icon: "offline_pin",
  //   href: "/projects",
  // },
  // {
  //   primaryText: "Uploads",
  //   icon: "publish",
  // },
  // {
  //   primaryText: "Backups",
  //   icon: "backup",
  // },
  // {
  //   primaryText: "Trash",
  //   icon: "delete",
  // },
];
const NavContentEx = () => (
  <List>
    {list.map(({ primaryText, icon, href }, i) => (
      <ListItem
        key={primaryText}
        selected={i === 5}
        component="a"
        button
        href={href}
        onClick={(event) => console.log(event)}
      >
        <ListItemIcon>{returnIcon(icon)}</ListItemIcon>

        <ListItemText
          primary={primaryText}
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>
    ))}
    <Divider style={{ margin: "12px 0" }} />
    <ListItem button component="a" href="/#/account">
      <ListItemIcon>
        <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText
        primary={"Settings & account"}
        primaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  </List>
);

NavContentEx.propTypes = {};
NavContentEx.defaultProps = {};

export default NavContentEx;
