import React from "react";
import { useContext, useState, useEffect, useRef } from "react";
import * as Realm from "realm-web";
import { useApp } from "./RealmApp";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Card,
  Typography,
  InputAdornment,
  Snackbar,
  Box,
  Modal,
  Tooltip,
} from "@mui/material";
import logo from "../images/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import logo2 from "../images/construction.png";
import logo3 from "../images/construction2.png";
import error from "../images/error.png";
import settingslogo from "../images/settings.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TaskColorTable from "./TaskColorTable";
import ImageMarkerColorLegend from "./ImageMarkerColorLegend";
import LinearProgress from "@mui/material/LinearProgress";
import Spreadsheet from "react-spreadsheet";
import { Grid, Input, Select } from "react-spreadsheet-grid";

import {
  Table,
  Tab,
  Tabs,
  Stack,
  Accordion,
  Navbar,
  Nav,
} from "react-bootstrap";
import {
  shared_TaskStatusOptions,
  getStatusColor,
  markerSizes,
  shared_TaskListOptions,
  generateId,
  basicDesignImage,
  invalidProjectModalStyle,
} from "../shared";
import TaskCompletionPercentTable from "./TaskCompletionPercentTable";
import GroupedTable from "./GroupedTable";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

export function ProjectPage() {
  const [excelData, setExcelData] = useState([
    [
      { value: "Vanilla" },
      { value: "Chocolate" },
      { value: "Chocolate" },
      { value: "Chocolate" },
      { value: "Chocolate" },
      { value: "Chocolate" },
      { value: "Chocolate" },
    ],
    [{ value: "Strawberry" }, { value: "Cookies" }],
  ]);
  const rows = [
    { id: "user1", name: "John Doe", positionId: "position1" },
    // and so on...
  ];
  const [snackBar, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    message: "",
    autoHideDuration: 2,
  });
  const { vertical, horizontal, open, message } = snackBar;

  const { currentUser, logOut } = useApp();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  let [markers, setMarkers] = useState([]);
  const [image, setImage] = useState(null);
  const [printMode, setPrintMode] = useState(false);
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [base64String, setBase64String] = useState(basicDesignImage);
  const [tasks, setTasks] = useState([]);
  // const [taskListOptions, setTaskListOptions] = useState(tasks);

  const saveTasks = async (items) => {
    setLoading(true);

    const result = await currentUser.functions.UpdateTasks(
      items ? items : tasks,
      project.project_id
    );
    if (result) {
      setLoading(false);
      setSnackBarState({
        ...snackBar,
        open: true,
        message: "Saved the tasks successfully.",
      });
    }
  };
  useEffect(async () => {
    setLoading(true);
    const getProject = async () => {
      try {
        const result = await currentUser.functions.GetProject(id);
        if (result) {
          //set the project
          setProject(result);
          //load the markers
          setMarkers(result.markers);
          setTasks(result.tasks);
          //set the project name and description
          setProjectName(result.project_name);
          setProjectDescription(result.project_description);
          setLoading(false);

          //load the image
          const imageObject = await currentUser.functions.GetProjectDesign(
            result.project_id
          );
          //se the image from the server if its not null
          if (imageObject) {
            setBase64String(imageObject.image);
          }
        } else {
          handleInvalidProjectModalOpen();
        }
      } catch (error) {
        console.log(error);
      }
    };

    getProject();
  }, []);

  const [taskStatusOptions, setTaskStatusOptions] = useState(
    shared_TaskStatusOptions
  );
  let [editMarker, setEditMarker] = useState(false);
  let [enableAddingOrEditingMarker, setEnableAddingOrEditingMarker] =
    useState("none");

  const [editTaskId, setEditTaskId] = useState(null);
  const [editTaskName, setEditTaskName] = useState("");
  const [newTask, setNewTask] = useState({ title: "", description: "" });

  const [editTaskDescription, setEditTaskDescription] = useState("");

  const handleEditTask = (task) => {
    setEditTaskId(task.id);
    setEditTaskName(task.name);
    setEditTaskDescription(task.description);
  };

  const handleSave = (taskToSave) => {
    const newTasks = tasks.map((task) =>
      task.id === taskToSave.id ? { ...task, ...taskToSave } : task
    );
    setTasks(newTasks);
    saveTasks(newTasks);
    setEditingTask(null);
  };
  const handleNewTaskChange = (event) => {
    setNewTask({ ...newTask, [event.target.name]: event.target.value });
  };

  const handleAddTask = () => {
    const newId = tasks.length + 1; //generateId(Math.random(), Math.random());
    const newTaskWithId = { ...newTask, id: newId };
    const newTasks = [...tasks, newTaskWithId];
    setTasks(newTasks);
    setNewTask({ name: "", description: "" });
    saveTasks(newTasks);
  };

  const handleCancelEdit = () => {
    setEditTaskId(null);
    setEditTaskName("");
    setEditTaskDescription("");
  };

  const handleSaveTask = () => {
    const newTasks = tasks.map((task) =>
      task.id === editTaskId
        ? { ...task, name: editTaskName, description: editTaskDescription }
        : task
    );

    //update the markers content accordingly if the task or task description is updated or changed.
    if (markers.length > 0) {
      setMarkers(
        markers.map((each) => {
          // console.log("each", each);
          // console.log("props", props);
          if (each.hasOwnProperty("taskid")) {
            if (each.taskid === editTaskId.toString()) {
              each.task = editTaskName;
              each.taskdescription = editTaskDescription;
            }
            return { ...each };
          } else {
            return each;
          }
        })
      );
    }
    //make sure you save the markers
    saveMarkersToCloud();
    setTasks(newTasks);
    saveTasks(newTasks);
    handleCancelEdit();
  };

  const handleDeleteTask = (taskId) => {
    const newTasks = tasks.filter((task) => task.id !== taskId);
    setTasks(newTasks);
    saveTasks();
  };
  // console.log(markers);
  const [invalidProjectModelOpen, setInvalidProjectModelOpen] =
    React.useState(false);
  const handleInvalidProjectModalOpen = () => setInvalidProjectModelOpen(true);
  const handleInvalidProjectModalClose = () =>
    setInvalidProjectModelOpen(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file != null) {
      if (file.size / 1024 / 1024 < 4) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          setBase64String(reader.result);
        };

        reader.onerror = () => {
          console.log("Error occurred while reading the file");
        };
      } else {
        setSnackBarState({
          ...snackBar,
          open: true,
          message: "Please upload a smaller file (<4MB)",
        });
      }
    }
  };

  const saveMarkersToCloud = async () => {
    setEditMarker(false);
    setEnableAddingOrEditingMarker("none");
    const result = await currentUser.functions.UpdateMarkers(
      markers,
      project.project_id
    );
    if (result) {
      setSnackBarState({
        ...snackBar,
        open: true,
        message: "Saved/Updated the markers successfully.",
      });
    }
  };

  const CustomMarker = (props: MarkerComponentProps) => {
    return (
      <div
        style={{ display: "flex" }}
        data-tooltip-id={`component_${props.itemNumber}`}
      >
        <div
          className="image-marker__marker image-marker__marker--default"
          data-testid="marker"
          style={{
            backgroundColor: getStatusColor(props.status),
            ...markerSizes,
          }}
        >
          {props.itemNumber + 1}
        </div>
        {editMarker && (
          <ReactTooltip
            openOnClick="true"
            id={`component_${props.itemNumber}`}
            place="top"
            variant="info"
            type="light"
            effect="float"
            // delayShow={1000}
            // delayHide={5000}
            closeOnEsc="true"
            // backgroundColor="blue"
          >
            <div>
              <b
                style={{
                  marginLeft: "0.75rem",
                  backgroundColor: "lightgray",
                  borderRadius: "6px",
                }}
              >
                {" "}
                For {props.itemNumber + 1}:{" "}
              </b>
              <select
                value={props?.task}
                style={{ marginLeft: "0.25rem", backgroundColor: "lightgray" }}
                onChange={(e) => {
                  setMarkers(
                    markers.map((each) => {
                      // console.log("each", each);
                      // console.log("props", props);
                      if (!each.hasOwnProperty("status")) {
                        each.status = "new";
                      }
                      if (each.top === props.top && each.left === props.left) {
                        if (!each.hasOwnProperty("status")) {
                          each.status = "new";
                        }
                        if (!each.hasOwnProperty("createdAt")) {
                          each.createdAt = new Date();
                        }
                        if (!each.hasOwnProperty("comments")) {
                          each.comments = [];
                        }

                        each.owner_id = currentUser.id;
                        each.project_id = id;
                        //set the task
                        each.task = e.target.value;
                        // console.log(each.task);
                        each.taskdescription = e.target.options[
                          e.target.selectedIndex
                        ].getAttribute("data-taskdescription");
                        each.taskid =
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "data-taskid"
                          );
                        //change color based on the task status
                        each.color = getStatusColor(each.status);
                        each.markerId = generateId(props.left, props.top);
                        each.markerItemId = props.itemNumber;
                        each.createdBy = currentUser.customData.userDisplayName;

                        return { ...each };
                      } else {
                        return each;
                      }
                    })
                  );
                }}
              >
                <option disabled selected value>
                  {" "}
                  -- select an option --{" "}
                </option>
                {tasks.map((option) => {
                  return (
                    <option
                      key={option.name}
                      value={option.name}
                      data-taskdescription={option.description}
                      data-taskid={option.id}
                    >
                      {option.name}
                    </option>
                  );
                })}
              </select>
              {/* Show the Status Select only when the Task has been selected */}
              {props.status && (
                <>
                  <select
                    value={props?.status}
                    style={{
                      marginLeft: "0.25rem",
                      backgroundColor: "lightgray",
                    }}
                    onChange={(e) => {
                      setMarkers(
                        markers.map((each) => {
                          // console.log("each", each);
                          // console.log("props", props);
                          if (
                            each.top === props.top &&
                            each.left === props.left
                          ) {
                            each.status = e.target.value;
                            each.markerId = generateId(props.left, props.top);
                            each.lastUpdated = new Date();
                            return { ...each };
                          } else {
                            return each;
                          }
                        })
                      );
                    }}
                  >
                    {taskStatusOptions.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>{" "}
                </>
              )}
              {/* <div style={{ color: "black", marginLeft: "1rem" }}>
              {props?.state}
            </div> */}
              <IconButton
                onClick={() => {
                  // console.log("marker", markers, "props", props);
                  // console.log("filter");
                  setMarkers(
                    markers.filter((el) =>
                      el?.top === props?.top && el?.left === props?.left
                        ? false
                        : true
                    )
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </ReactTooltip>
        )}
      </div>
    );
  };

  return (
    <Container className="main-container">
      <center>
        <LinearProgress
          style={loading ? { visibility: "visible" } : { visibility: "hidden" }}
        />
      </center>
      <Snackbar
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        action={
          <React.Fragment>
            <Button
              color="primary"
              size="large"
              onClick={() =>
                setSnackBarState({
                  ...snackBar,
                  open: false,
                  message: "",
                })
              }
            >
              DISMISS
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() =>
                setSnackBarState({
                  ...snackBar,
                  open: false,
                  message: "",
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        // onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
      <Modal
        open={invalidProjectModelOpen}
        onClose={handleInvalidProjectModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={invalidProjectModalStyle}>
          <img src={error} width="60" height="60" />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Invalid Project{" "}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Either you do not have the permission to view this project or the
            project doesn't exist.
          </Typography>
          <br />
          <center>
            <Button component="a" href="/#/projects" variant="contained">
              Back to projects
            </Button>
          </center>
        </Box>
      </Modal>
      <Tabs defaultActiveKey="home" className="mb-3">
        <Tab eventKey="home" title="Home">
          {base64String && (
            <>
              <Typography variant="h5">
                <center>{projectName}</center>
              </Typography>

              <div className="bg-light p-2 text-center">
                <Tooltip title="This will delete all the markers on the screen.">
                  <Button
                    variant="contained"
                    color="error"
                    size="sm"
                    disabled={!markers.length > 0}
                    onClick={() => {
                      if (
                        confirm("Confirm removal of all the tracking items ? ")
                      ) {
                        setMarkers([]);
                      } else {
                      }
                    }}
                  >
                    Delete All Markers
                  </Button>
                </Tooltip>
                <Button
                  size="sm"
                  style={{ margin: "0.5rem" }}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    setEditMarker(true);
                    setEnableAddingOrEditingMarker("auto");
                  }}
                >
                  Edit Mode
                </Button>
                <Tooltip title="Markers are saved only when you hit on save changes button">
                  <Button
                    size="sm"
                    variant="contained"
                    color="success"
                    disabled={!editMarker}
                    onClick={saveMarkersToCloud}
                  >
                    Save Changes
                  </Button>
                </Tooltip>
              </div>

              <div style={{ pointerEvents: enableAddingOrEditingMarker }}>
                <ImageMarker
                  src={base64String}
                  // src={URL.createObjectURL(image)}
                  markers={markers}
                  onAddMarker={(marker) => {
                    const newMarker: MarkerComponentProps = {
                      top: marker.top,
                      left: marker.left,
                      // status: "new",
                      // createdAt: new Date(),
                      // createdBy: "Medha",
                      // itemNumber: marker.itemNumber,
                    };
                    setMarkers((prev) => [...prev, newMarker]);
                  }}
                  markerComponent={CustomMarker}
                  // markerRadius={10}
                  // width={600} // set the image width
                  // height={400} // set the image height
                />
              </div>
              <ImageMarkerColorLegend />
              <TaskCompletionPercentTable
                markers={markers}
                projectName={projectName}
              ></TaskCompletionPercentTable>
            </>
          )}
          {!base64String && (
            <div className="container">
              <h1> Welcome!</h1>
              <img src={logo} />
              <img src={logo2} />
              <img src={logo3} />
              <h4>
                Please setup your project using the project configuration tab
              </h4>
            </div>
          )}
        </Tab>
        <Tab eventKey="reports" title="Reports">
          <TaskCompletionPercentTable
            markers={markers}
            currentUser={currentUser}
            projectName={projectName}
          ></TaskCompletionPercentTable>
          {/* <TaskListTable markers={markers} tasks={tasks} /> */}
          <ImageMarkerColorLegend />
          <GroupedTable data={markers} />
        </Tab>
        <Tab eventKey="tasks" title="Tasks">
          <div>
            <h3>Available Tasks</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) =>
                  task.id === editTaskId ? (
                    <tr key={task.id}>
                      <td>{task.id}</td>
                      <td>
                        <input
                          type="text"
                          value={editTaskName}
                          onChange={(e) => setEditTaskName(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={editTaskDescription}
                          onChange={(e) =>
                            setEditTaskDescription(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button onClick={handleSaveTask}>Save</button>
                        <button onClick={handleCancelEdit}>Cancel</button>
                      </td>
                    </tr>
                  ) : (
                    <tr key={task.id}>
                      <td>{task.id}</td>
                      <td>{task.name}</td>
                      <td>{task.description}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="success"
                          size="sm"
                          onClick={() => handleEditTask(task)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="sm"
                          onClick={() => handleDeleteTask(task.id)}
                          style={{ marginLeft: "0.25rem" }}
                          disabled
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            <div>
              <label>Title:</label>
              <input
                type="text"
                name="name"
                value={newTask.name}
                onChange={handleNewTaskChange}
              />
              <label>Description:</label>
              <input
                type="text"
                name="description"
                value={newTask.description}
                onChange={handleNewTaskChange}
              />
              <Button
                onClick={handleAddTask}
                variant="contained"
                color="success"
                size="sm"
                style={{ marginLeft: "0.25rem" }}
              >
                Add New Task
              </Button>
            </div>
          </div>
        </Tab>
        <Tab eventKey="settings" title="Project Configuration">
          <div className="container">
            <center>
              <img src={settingslogo} />
              <h1>Project Configuration</h1>
            </center>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Step 1: Basic Information</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack direction="vertical">
                    Project Name
                    <br />
                    <input
                      value={projectName}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setProjectName(value);
                      }}
                    />
                    <br />
                    Project Description
                    <input
                      value={projectDescription}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setProjectDescription(value);
                      }}
                    />
                    <br />
                    <Button
                      variant="contained"
                      color="success"
                      size="sm"
                      // disabled={disableProjectName}
                      onClick={async () => {
                        if (project) {
                          setLoading(true);
                          const projectObject = {
                            project_id: project.project_id,
                            project_name: projectName,
                            project_description: projectDescription,
                          };
                          const result =
                            await currentUser.functions.UpdateProject(
                              projectObject
                            );
                          if (result) {
                            setLoading(false);

                            setSnackBarState({
                              ...snackBar,
                              open: true,
                              message: "Saved.",
                            });
                          }
                        } else {
                          setSnackBarState({
                            ...snackBar,
                            open: true,
                            message:
                              "Either the project doesn't exist or you do not have permission",
                          });
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <h4>Step 2: Upload your design</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h3>
                    <center>Please setup the layout/design diagram</center>
                  </h3>
                  Upload an image
                  <Stack gap={3} direction="vertical" className="mx-auto">
                    <input
                      className="bg-light border"
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      onChange={handleImageUpload}
                    />
                    <Button
                      size="sm"
                      variant="contained"
                      color="success"
                      onClick={async () => {
                        if (project) {
                          const imageObject = {
                            project_id: id,
                            image: base64String,
                          };
                          try {
                            setLoading(true);
                            const result =
                              await currentUser.functions.CreateProjectDesign(
                                imageObject
                              );
                            if (result) {
                              setProject(result);
                              setProjectName(result.project_name);
                              setProjectDescription(result.project_description);
                              setLoading(false);
                              setSnackBarState({
                                ...snackBar,
                                open: true,
                                message: "Uploaded the image successfully.",
                              });
                            } else {
                              setLoading(true);
                              setSnackBarState({
                                ...snackBar,
                                open: true,
                                message:
                                  "Please try again later. Couldn't save the image",
                              });
                            }
                          } catch (error) {
                            setLoading(false);
                            setSnackBarState({
                              ...snackBar,
                              open: true,
                              message:
                                "Please try again later. Couldn't save the image",
                            });
                          }
                        } else {
                          setSnackBarState({
                            ...snackBar,
                            open: true,
                            message: "Invalid Project",
                          });
                        }
                      }}
                    >
                      Save Image
                    </Button>
                    <LinearProgress
                      style={
                        loading
                          ? { visibility: "visible" }
                          : { visibility: "hidden" }
                      }
                    />
                    Image Preview:
                    <center>
                      <img
                        className="bg-light border"
                        src={base64String}
                        width={500}
                        height={500}
                      />
                    </center>
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h4>Optional Configuration</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Button
                    variant="contained"
                    color="error"
                    size="m"
                    onClick={() => {
                      if (
                        confirm(
                          "Confirm reset project ? This action will remove everything (image, tasks, markers) and cannot be undo."
                        )
                      ) {
                        localStorage.clear();
                        window.location.reload();
                      } else {
                      }
                    }}
                  >
                    Reset Everything
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h4>Excel Sheet</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Spreadsheet data={excelData} onChange={setExcelData} />
                  <Grid
                    columns={[
                      {
                        title: () => "Task",
                        value: (row, { focus }) => {
                          return <Input value={row.task} focus={focus} />;
                        },
                      },
                      {
                        title: () => "Status",
                        value: (row, { focus }) => {
                          return (
                            <Select
                              value={row.status}
                              isOpen={focus}
                              items={shared_TaskStatusOptions}
                            />
                          );
                        },
                      },
                    ]}
                    rows={markers}
                    getRowKey={(row) => row.markerId}
                  />
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
}
