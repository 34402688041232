import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { isWidthUp } from "@material-ui/core/withWidth";
import InputBase from "@material-ui/core/InputBase";
import { Form, Stack } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import Icon from "@material-ui/core/Icon";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
const styles = ({ spacing, transitions, breakpoints, palette, shape }) => ({
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18,
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginRight: 8,
    borderRadius: shape.borderRadius,
    background: palette.grey[200],
    "&:hover": {
      background: palette.grey[300],
    },
    marginLeft: 0,
    width: "100%",
    [breakpoints.up("sm")]: {
      marginLeft: spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    width: spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    borderRadius: 4,
    paddingTop: spacing(1),
    paddingRight: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(10),
    transition: transitions.create("width"),
    width: "100%",
    [breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
});

function handleClick() {
  // Handle click event here
  console.log("IconButton clicked!");
}

const HeaderEx = ({ classes, screen, currentUser, logOut }) => (
  <>
    <Typography noWrap color={"textSecondary"} className={classes.header}>
      {currentUser.customData && currentUser.customData.userBusinessName}
    </Typography>
    <div className={classes.grow} />
    {/* <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
    </div> */}
    <Tooltip title="Create Organization">
      <IconButton
        disabled
        component={Link}
        to="/organizations/new"
        onClick={async () => await logOut()}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="Logout">
      <IconButton onClick={async () => await logOut()}>
        <LogoutIcon />
      </IconButton>
    </Tooltip>
    {/* {screen === "xs" && (
      <IconButton>
        <Icon>more_vert</Icon>
      </IconButton>
    )}
    {screen === "sm" && (
      <>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>more_vert</Icon>
        </IconButton>
      </>
    )}
    {isWidthUp("md", screen) && (
      <>
        <IconButton>
          <Icon>favorite</Icon>
        </IconButton>
        <IconButton>
          <Icon>phone</Icon>
        </IconButton>
        <IconButton>
          <Icon>camera</Icon>
        </IconButton>
      </>
    )} */}
  </>
);

HeaderEx.propTypes = {
  screen: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};
HeaderEx.defaultProps = {
  screen: null,
};

export default withStyles(styles)(HeaderEx);
