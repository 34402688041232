import {
  green,
  amber,
  purple,
  orange,
  red,
  blue,
  teal,
  blueGrey,
  lightBlue,
} from "@mui/material/colors";

export const colors = {
  green: {
    ...green,
    500: "#09804C",
    A400: "#B9EACD",
  },
  purple: {
    ...purple,
    500: "#6200EE",
    A400: "#9795F9",
  },
  orange,
  red,
  blue,
  amber,
  teal,
  blueGrey,
  lightBlue,
};
