import React from "react";
import * as Realm from "realm-web";
import { useApp } from "./RealmApp";
import { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Card,
  Typography,
  InputAdornment,
  Grid,
  Box,
} from "@mui/material";
import { Stack } from "react-bootstrap";
import ClockGadget from "./ClockGadget";

export function DashboardPage() {
  const { currentUser, app } = useApp();
  const [dashboardData, setDashboardData] = useState({
    projects: 0,
    organizations: 0,
  });
  const getStats = async () => {
    try {
      const result = await currentUser.functions.GetDashboardAnalytics();
      setDashboardData(result);
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(async () => {
    getStats();
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} style={{ margin: "5px" }}>
        <Grid container spacing={1}>
          <Grid item>
            <Card className="dashboard-card" variant="elevation">
              <ClockGadget />
              Current Time
            </Card>
          </Grid>
          <Grid item>
            <Card
              className="dashboard-card"
              variant="elevation"
              style={{ margin: "5px" }}
            >
              <Typography variant="h3">{dashboardData.boards}</Typography>
              <a href="/#/boards">Boards</a>
            </Card>
            <Card
              className="dashboard-card"
              variant="elevation"
              style={{ margin: "5px" }}
            >
              <Typography variant="h3">{dashboardData.projects}</Typography>
              <a href="/#/projects">Floorplan Projects</a>
            </Card>
          </Grid>
          <Grid item>
            <Card
              className="dashboard-card"
              variant="elevation"
              style={{ margin: "5px" }}
            >
              <Typography variant="h3">
                {dashboardData.organizations}
              </Typography>
              Organizations
            </Card>
          </Grid>
        </Grid>
      </Box>
      <br />
      <br />
      <br />
    </>
  );
}
