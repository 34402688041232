import React from "react";
import img404 from "../images/404.jpg";

const NotFoundPage = () => {
  return (
    <center>
      <img src={img404} width="700" height="500" />
      <h1>Sorry, page not found.</h1>

      <h4>
        <a href="/">Go Back Home</a>
      </h4>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h6>
        <a href="https://www.freepik.com/free-vector/computer-trouble-shooting-concept-illustration_18771510.htm#query=404&position=37&from_view=search&track=sph">
          Image by storyset
        </a>{" "}
        on Freepik
      </h6>
    </center>
  );
};

export default NotFoundPage;
