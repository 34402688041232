import React from "react";
import ColorSquare from "react-color-square";
import { getStatusColor } from "../shared";

const GroupedTable = ({ data }) => {
  const groupedData = data.reduce((acc, item) => {
    if (acc[item.task]) {
      acc[item.task].push(item);
    } else {
      acc[item.task] = [item];
    }
    return acc;
  }, {});

  return (
    <div className="container my-4">
      <h2>All Tasks Grouped by Task Type</h2>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Marker Id</th>
            <th>Task Name</th>

            <th>Status</th>
            <th>Last Updated At</th>
            <th>Created By</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).map((taskName) => {
            return (
              <React.Fragment key={taskName}>
                <tr className="table-dark">
                  <td colSpan="6">
                    <b>
                      {taskName} ({groupedData[taskName].length})
                    </b>
                  </td>
                </tr>
                {groupedData[taskName].map((task) => (
                  <tr key={task.markerId}>
                    <td>{task.markerId ? task.markerItemId + 1 : "N/A"}</td>
                    <td>{task.task}</td>
                    <td>
                      <ColorSquare
                        height={10}
                        width={10}
                        color={getStatusColor(task.status)}
                      />
                      <div style={{ color: getStatusColor(task.status) }}>
                        {task.status}
                      </div>
                    </td>
                    <td>{new Date(task.lastUpdated).toLocaleString()}</td>
                    <td>{task.createdBy}</td>
                    <td>{task.comments}</td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GroupedTable;
