import * as Realm from "realm-web";
import { useApp } from "./RealmApp";
import React, { useContext, useState, useEffect } from "react";
import { Form, Stack } from "react-bootstrap";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Card,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { Accordion } from "react-bootstrap";
import projectImage from "../images/project.png";
import createProjectImage from "../images/createProject.png";
import duplicateProjectImage from "../images/duplicateProjectImage.png";
import { Toolbar } from "@material-ui/core";

export function ProjectOptionsPage() {
  const { currentUser, logOut } = useApp();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState("none");
  const [selectedCopyToOrganizationId, setSelectedCopyToOrganizationId] =
    useState("none");
  const [selectedProjectId, setSelectedProjectId] = useState("none");
  const [disableDuplicateProject, setDisableDuplicateProject] = useState(true);
  const [disableCreateProject, setDisableCreateProject] = useState(false);

  const getProjects = async (orgId) => {
    try {
      const filter = { organizationId: orgId ? orgId : selectedOrganizationId };
      const result = await currentUser.functions.GetProjects(filter);
      //console.log(result);
      setProjects(result);
      //check for the premium subscription
      if (result.length >= 1 && currentUser.customData.userPlan === "free") {
        setDisableCreateProject(true);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getOrganizations = async () => {
    try {
      const filter = {};
      const result = await currentUser.functions.GetOrganizations(filter);
      setOrganizations(result);
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(async () => {
    getOrganizations();
    if (currentUser.customData.userPlan === "premium") {
      setDisableDuplicateProject(false);
    }
  }, []);
  return (
    <Container
      maxWidth="m"
      className="main-container"
      style={{ margin: "12px" }}
    >
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <img src={projectImage} width="50" />
            <h4>Existing Project</h4>
          </Accordion.Header>
          <Accordion.Body>
            <Card className="auth-card" variant="outlined">
              {/* <Typography variant="h1">Load Project</Typography> */}
              {/* <h1>Load Project</h1> */}
              <Form.Group>
                <Form.Label>Choose an organization:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOrganizationId}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedOrganizationId(e.target.value);
                    getProjects(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select an organization
                  </option>
                  {organizations.map((organization) => (
                    <option value={organization.organizationId}>
                      {organization.organizationName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Choose a project:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedProjectId}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedProjectId(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select a project
                  </option>
                  {projects.map((project) => (
                    <option value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Button
                type="primary"
                variant="contained"
                color="primary"
                component="a"
                disabled={selectedProjectId === "none"}
                href={"/#/projects/" + selectedProjectId}
              >
                Load
              </Button>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img src={createProjectImage} width="50" />
            <h4>New Project</h4>
          </Accordion.Header>
          <Accordion.Body>
            <Card className="auth-card" variant="outlined">
              <h4>Select Organization</h4>
              <Form.Group>
                <Form.Label>Choose an organization:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOrganizationId}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedOrganizationId(e.target.value);
                    getProjects(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select an organization
                  </option>
                  {organizations.map((organization) => (
                    <option value={organization.organizationId}>
                      {organization.organizationName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <h4>Enter Project Details</h4>
              <Stack direction="vertical">
                Enter the name of the project
                <br />
                <input
                  disabled={disableCreateProject}
                  value={projectName}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setProjectName(value);
                  }}
                />
                <br />
                Enter the description for the project
                <input
                  disabled={disableCreateProject}
                  value={projectDescription}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setProjectDescription(value);
                  }}
                />
                <br />
                <Button
                  variant="contained"
                  color="success"
                  size="sm"
                  disabled={disableCreateProject}
                  onClick={async () => {
                    if (
                      projectName.trim() != "" &&
                      selectedOrganizationId != "none"
                    ) {
                      if (confirm("Confirm creating the project?")) {
                        const result =
                          await currentUser.functions.CreateProject({
                            project_description: projectDescription,
                            project_name: projectName,
                            organizationId: selectedOrganizationId,
                          });
                        if (result) {
                          setSelectedOrganizationId("none");
                          setProjects([]);
                          alert(
                            "Created the project successfully! You may use the existing project functionality to continue with the next steps."
                          );
                          setProjectDescription("");
                          setProjectName("");
                          setSelectedOrganizationId("none");
                        }
                      }
                    } else {
                      alert(
                        "Make sure you choose an organization and enter the project name"
                      );
                    }
                  }}
                >
                  Create Project
                </Button>
                {currentUser.customData.userPlan === "free" && (
                  <>
                    <br />
                    <b>
                      Note: You are limited to creating only one project in the
                      free plan.
                    </b>
                  </>
                )}
              </Stack>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <img src={duplicateProjectImage} width="50" />
            <h4>Duplicate Project</h4>
          </Accordion.Header>
          <Accordion.Body>
            <Card className="auth-card" variant="outlined">
              <Form.Group>
                <Form.Label>From this organization:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOrganizationId}
                  disabled={disableDuplicateProject}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedOrganizationId(e.target.value);
                    getProjects(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select an organization
                  </option>
                  {organizations.map((organization) => (
                    <option value={organization.organizationId}>
                      {organization.organizationName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Copy this project:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedProjectId}
                  disabled={disableDuplicateProject}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedProjectId(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select a project
                  </option>
                  {projects.map((project) => (
                    <option value={project.project_id}>
                      {project.project_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>In to this organization:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedCopyToOrganizationId}
                  disabled={disableDuplicateProject}
                  onChange={(e) => {
                    //console.log("e.target.value", e.target.value);
                    setSelectedCopyToOrganizationId(e.target.value);
                  }}
                >
                  <option value="none" disabled>
                    Select an organization
                  </option>
                  {organizations.map((organization) => (
                    <option value={organization.organizationId}>
                      {organization.organizationName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <br />
              <Stack direction="vertical">
                Name of the project:
                <br />
                <input
                  value={projectName}
                  disabled={disableDuplicateProject}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setProjectName(value);
                  }}
                />
                <br />
                Description for the project:
                <br />
                <input
                  value={projectDescription}
                  disabled={disableDuplicateProject}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setProjectDescription(value);
                  }}
                />
                <br />
                <b>
                  Note: This will not copy the design (image) of the project
                </b>
                <br />
                <Button
                  variant="contained"
                  color="success"
                  size="sm"
                  disabled={disableDuplicateProject}
                  onClick={async () => {
                    if (
                      projectName.trim() != "" &&
                      selectedOrganizationId != "none" &&
                      (selectedProjectId != "none" &&
                        selectedCopyToOrganizationId) != "none"
                    ) {
                      if (
                        confirm("Confirm duplicating the selected project ?")
                      ) {
                        const result =
                          await currentUser.functions.DuplicateProject({
                            source_org_id: selectedOrganizationId,
                            dest_org_id: selectedCopyToOrganizationId,
                            source_project_id: selectedProjectId,
                            dup_project_name: projectName,
                            dup_project_description: projectDescription,
                          });
                        if (result) {
                          setProjects([]);
                          setProjectDescription("");
                          setProjectName("");
                          setSelectedOrganizationId("none");
                          setSelectedCopyToOrganizationId("none");
                          alert(
                            "Duplicated the project successfully! You may use the existing project functionality to continue with the next steps."
                          );
                        }
                      }
                    } else {
                      alert("Make sure you fill in all the details");
                    }
                  }}
                >
                  Duplicate Project
                </Button>
                {currentUser.customData.userPlan === "free" && (
                  <>
                    <br />
                    This feature requires a premium account.
                  </>
                )}
              </Stack>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
