import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const FooterEx = ({ currentUser }) => (
  <div style={{ maxWidth: 700, margin: "auto", textAlign: "center" }}>
    {currentUser.customData.userBusinessName && (
      <>
        <Typography variant="caption" align={"center"}>
          For {currentUser.customData.userBusinessName}
        </Typography>
        <br />
      </>
    )}
    <Typography
      variant="caption"
      align={"center"}
      component="a"
      href="https://sitepilot.copeo.app"
    >
      https://sitepilot.copeo.app
    </Typography>

    {currentUser.customData.userPlan === "free" && (
      <>
        <br />
        <a href="mailto:medhachaitanya@outlook.com" target="_blank">
          Request Premium Account&nbsp;
        </a>
        <br />
      </>
    )}
    <br />
    <Typography variant="caption" align={"center"}>
      Medha Karri © Copyright 2023
    </Typography>
    {/* <Divider style={{ margin: "24px auto", width: 60 }} /> */}
    {/* <Grid container justifyContent={"center"} spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <Typography align={"center"} gutterBottom color={"textSecondary"}>
          About
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography align={"center"} gutterBottom color={"textSecondary"}>
          Blog
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography align={"center"} gutterBottom color={"textSecondary"}>
          Terms & Conditions
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography align={"center"} gutterBottom color={"textSecondary"}>
          Contact us
        </Typography>
      </Grid>
    </Grid> */}
  </div>
);

FooterEx.propTypes = {};
FooterEx.defaultProps = {};

export default FooterEx;
