import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ColorSquare from 'react-color-square';
import { shared_TaskStatusOptions, getStatusColor } from "../shared"
import Stack from 'react-bootstrap/Stack';



const colorLegend = shared_TaskStatusOptions;

function ImageMarkerColorLegend() {
    return (

        <Container>
            <Stack direction="horizontal" gap={3}>
                {colorLegend.map((color, index) => (
                    <>
                        <Stack direction="horizontal" gap={2}>
                            <ColorSquare height={10} width={10} color={color.color} />
                            <div>{color.label}</div>
                        </Stack>

                    </>
                ))}
            </Stack>

            {/* <Row className="justify-content-center">
        {colorLegend.map((color, index) => (
          <Col xs="auto" key={index} className="text-center">
            <ColorSquare height={20} text={color.label} color={color.color} />
            <div>{color.name}</div>
          </Col>
        ))}
      </Row> */}
        </Container>

    );
}

export default ImageMarkerColorLegend;
