import { useEffect, useState } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@material-ui/core/Typography";
import { Root, Header, Nav, Content, Footer, presets } from "mui-layout";
import NavContentEx from "./NavContentEx";
import NavHeaderEx from "./NavHeaderEx";
import HeaderEx from "./HeaderEx";
import ContentForm from "./ContentForm";
import ContentEx from "./ContentEx";
import FooterEx from "./FooterEx";
import { AppProvider, useApp } from "./RealmApp";
import { defaultProfileImage } from "../shared";
const AppLayout = () => {
  const { currentUser, logOut } = useApp();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [userProfileImageBase64, setUserProfileImageBase64] =
    useState(defaultProfileImage);

  const [preset, setPreset] = useState("createFixedLayout");
  const [data, setData] = useState({
    header: true,
    nav: true,
    content: true,
    footer: true,
  });

  return (
    <MuiThemeProvider theme={createTheme()}>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant={"h2"}>{loadingText}</Typography>
        </div>
      ) : (
        <Root
          config={presets[preset]({ headerPosition: "relative" })}
          style={{ minHeight: "100vh" }}
        >
          <CssBaseline />
          <Header
            renderMenuIcon={(opened) =>
              opened ? <ChevronLeftIcon /> : <ChevronRightIcon />
            }
          >
            {({ screen, collapsed }) =>
              data.header && (
                <HeaderEx
                  screen={screen}
                  collapsed={collapsed}
                  currentUser={currentUser}
                  logOut={logOut}
                />
              )
            }
          </Header>
          <Nav
            renderIcon={(collapsed) =>
              collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />
            }
            header={({ collapsed }) =>
              data.nav && (
                <NavHeaderEx
                  collapsed={collapsed}
                  currentUser={currentUser}
                  userProfileImage={userProfileImageBase64}
                />
              )
            }
          >
            {data.nav && <NavContentEx />}
          </Nav>
          <Content>
            <ContentForm
              preset={preset}
              onChangePreset={(val) => {
                setLoading(true);
                setPreset(val);
                setTimeout(() => setLoading(false), 400);
              }}
              data={data}
              onChangeData={setData}
            />
            {data.content && <ContentEx />}
          </Content>
          <Footer>
            {data.footer && (
              <FooterEx
                companyName={currentUser.customData.userBusinessName}
                currentUser={currentUser}
              />
            )}
          </Footer>
        </Root>
      )}
    </MuiThemeProvider>
  );
};

export default AppLayout;
